 <!-- ======= Footer ======= -->
 <footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div class="col-lg-4 col-md-6 footer-info">
          <h3>INSEVEN Technologies</h3>
          <p>
            #3, 2nd Floor, 1st Cross, Sharada Nagar, <br>
            Uttarahalli-Vasanthapura Main Road,<br>
            Bangalore:-560061<br>
            <br>
            <strong>Phone:</strong> +91 9035203039<br>
            <strong>Email:</strong> contact@inseventechnologies.com<br>
            <strong>Email:</strong> inseventech@gmail.com<br>
          </p>

        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/home">Home</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/services">Services</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/portfolio">Portfolio</a></li>
            <!-- <li><i class="bx bx-chevron-right"></i> <a href="Term of Service.html">Terms of service</a></li> -->
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/career">Career</a></li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Our Services</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/services">ERP</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/services">Web Design & Development</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/services">Mobile App Design</a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/services">Customer Application</a></li>
          </ul>
        </div>

        <div class="col-lg-2 col-md-6 footer-info">
          <h4>Our Social Networks</h4>
          <div class="social-links mt-3">
            <!-- <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a> -->
            <a href="https://www.facebook.com/InsevenTechnologies" target="blank" class="facebook"><i class="bx bxl-facebook"></i></a>
            <!-- <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a> -->
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="container">
    <div class="copyright">
      &copy; Copyright <strong><span>INSEVEN Technologies</span></strong>. All Rights Reserved
    </div>
  <div class="credits">

    </div>
  </div>
</footer><!-- End Footer -->

<a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a>

