import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.metaService.updateTag({ name: 'description', content: 'Let’s talk business, say hello!' });
    this.titleService.setTitle('INSEVEN Technologies | Contact Us - Questions & Requests');
    const findlink = document.getElementsByTagName('link');
    findlink[0].href = 'https://inseventechnologies.com/contact';
  }


}
