 <!-- ======= Breadcrumbs ======= -->
 <section id="breadcrumbs" class="breadcrumbs">
  <div class="container">

    <div class="d-flex justify-content-between align-items-center">
      <h2>Team</h2>
      <ol>
        <li><a routerLink="/home">Home</a></li>
        <li>Team</li>
      </ol>
    </div>

  </div>
</section><!-- End Breadcrumbs -->


<main id="main">
  <!-- ======= Team Section ======= -->
  <section id="team" class="team">
    <div class="container">

      <div class="section-title">

        <h2> Our Team</h2>

      </div>

      <div class="row">

        <div class="col-lg-6">
          <div class="member d-flex align-items-start">
            <div class="pic"><img src="assets/img/undraw_male_avatar_323b.svg" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>Harish M A</h4>
              <span>Founder & Director</span>
              <!-- <p></p> -->
              <div class="social">
                <a href="#" class="twitter"><i class="icofont-twitter"></i></a>
                <a href="#" class="facebook"><i class="icofont-facebook"></i></a>
                <a href="#" class="instagram"><i class="icofont-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 mt-4 mt-lg-0">
          <div class="member d-flex align-items-start">
            <div class="pic"><img src="assets/img/team/Girish.jpg" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>Girish M A</h4>
              <span>Chief Executive Officer</span>
              <!-- <p></p> -->
              <div class="social">

                <a href="#" class="twitter"><i class="icofont-twitter"></i></a>
                <a href="#" class="facebook"><i class="icofont-facebook"></i></a>
                <a href="#" class="instagram"><i class="icofont-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><!-- End Team Section -->


</main><!-- End #main -->
