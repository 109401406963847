import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.metaService.updateTag({ name: 'description', content: 'Always on the lookout for passionate individuals. Reach out to us' });
    this.titleService.setTitle('INSEVEN Technologies | Career');
    const findlink = document.getElementsByTagName('link');
    findlink[0].href = 'https://inseventechnologies.com/career';
  }

}
