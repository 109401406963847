 <!-- ======= Top Bar ======= -->
 <section id="topbar" class="d-none d-lg-block">
  <div class="container clearfix">
    <div class="contact-info float-left">
      <i class="icofont-envelope"></i><a
        href="mailto:contact@inseventechnologies.com">contact@inseventechnologies.com</a>
      <i class="icofont-envelope"></i><a href="mailto:inseventech@gmail.com">inseventech@gmail.com</a>
      <i class="icofont-phone"></i><a href="tel:+91 9035203039">+91 9035203039</a>
    </div>
    <div class="social-links float-right">
      <!-- <a href="#" class="twitter"><i class="icofont-twitter"></i></a> -->
      <a href="https://www.facebook.com/InsevenTechnologies" target="blank" class="facebook"><i class="bx bxl-facebook"></i></a>
      <!-- <a href="#" class="instagram"><i class="icofont-instagram icofont-facebook"></i></a> -->
    </div>
  </div>
</section>

<div class="icons">
  <a href="https://www.facebook.com/InsevenTechnologies" target="_blank" class="facebook1"><i class="fab fa-facebook-f"></i>Facebook </a>
  <a href="tel:+91 9035203039" target="_blank" class="youtube"><i class="fas fa-phone" aria-hidden="true"></i>Call Us </a>
  <a href="sms://+919035203039" target="_blank" class="twitter"><i class="fas fa-envelope" aria-hidden="true"></i>SMS</a>
  <a href="https://api.whatsapp.com/send?phone=919035203039&text=Hello" target="blank" class="whatsapp"><i class="fab fa-whatsapp" aria-hidden="true"></i>Whatsapp</a>
</div>

<div class="float-sm">
  <!-- <div class="fl-fl float-Ad">
    <i class="fa fa-university"></i>
    <a routerLink="/career" >Career!!</a>
  </div> -->
  <!-- <div class="fl-fl float-tw">
    <i class="fa fa-phone"></i>
    <a href="tel:+91 9035203039" target="_blank">Call us!</a>
  </div>
  <div class="fl-fl float-gp">
    <i class="fa fa-envelope"></i>
    <a href="sms://+919035203039" target="_blank">SMS</a>
  </div>
  <div class="fl-fl float-rs">
    <i class="fa fa-whatsapp"></i>
    <a href="tel:+91 9035203039" target="_blank">Whatsapp!</a>
  </div>
  <div class="fl-fl float-ig">
    <i class="fa fa-facebook"></i>
    <a href="" target="_blank">Facebook</a>
  </div> -->
  <!-- <div class="fl-fl float-pn">
    <i class="fa fa-instagram"></i>
    <a href="" target="_blank">Follow Us!</a>
  </div> -->

</div>
<!-- Floating Social Media bar Ends -->
