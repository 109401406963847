<!-- ======= Breadcrumbs ======= -->
<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">

    <div class="d-flex justify-content-between align-items-center">
      <h2>Services</h2>
      <ol>
        <li><a routerLink="/home">Home</a></li>
        <li>Services</li>
      </ol>
    </div>

  </div>
</section><!-- End Breadcrumbs -->


<main id="main">
  <!-- ======= Services Section ======= -->
  <section id="services" class="services section-bg">
    <div class="container">
      <div class="section-title">
        <h2 data-aos="fade-in">Services</h2>
        <!-- <p data-aos="fade-in"></p> -->
      </div>

      <div class="row">
        <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-right">
        <div class="card">
            <div class="card-img">
              <img src="assets/img/website design1.jpg" alt="Web Designing and Development" />
            </div>
            <div class="card-body">
              <h5 class="card-title">
                <a href="">Web Designing and Development </a>
              </h5>
              <p class="card-text text-justify">
                In this digital era, a website is the first sales representative of any company. Our dedicated team of designers and developers ensures your website scores high in functionality and user experience, and needless to say, ranks high in search results. Simple, yet classy websites are what we specialise in.
              </p>
              </div>
          </div>

        </div>
        <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-left">
          <div class="card">
            <div class="card-img">
              <img src="assets/img/ERP1.jpg" alt="ERP" />
            </div>
            <div class="card-body">
              <h5 class="card-title">
                <a href="">ERP</a>
              </h5>
              <p class="card-text text-justify">
                Simplify your business with our ERP solutions. While our efficient solutions automate your business by bringing all your activities in one place, you can focus on what’s necessary- your customers and revenues.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-right">
          <div class="card">
            <div class="card-img">
              <img src="assets/img/Mobile App.jpg" alt="Mobile App Development" />
            </div>
            <div class="card-body">
              <h5 class="card-title">
                <a href="">Mobile App Design </a>
              </h5>
              <p class="card-text text-justify">
                Our creatively-designed apps are made to be snappy with simple yet aesthetically pleasing interface.
                 We develop apps your customers would love to use.
              </p>

            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-left">
          <div class="card">
            <div class="card-img">
              <img src="assets/img/Customer Application.jpg" alt="Customer Application" />
            </div>
            <div class="card-body">
              <h5 class="card-title">
                <a href="">Customer Applications </a>
              </h5>
              <p class="card-text text-justify">
                We love taking challenges head on.
                Any software requirement, any industry, we are game.
                Our softwares are highly efficient and come at pocket-friendly prices.
              </p>

            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
  <!-- End Services Section -->
  <!-- ======= Clients Section ======= -->
  <!-- End Clients Section -->


</main><!-- End #main -->
