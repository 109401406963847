<!-- ======= Hero Section ======= -->
<section id="hero">
  <div class="hero-container">
    <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel">

      <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

      <div class="carousel-inner" role="listbox">

        <!-- Slide 1 -->
        <div class="carousel-item active" style="background-image: url('assets/img/slide/bg_11.jpg');">
          <div class="carousel-container">
            <div class="carousel-content container">
              <h2 class="animated fadeInDown">Coding meets Creativity</h2>
              <p class="animated fadeInUp">We combine the power of functionality with the brilliance of design and
                user experience.</p>
              <!-- <a href="#about" class="btn-get-started animated fadeInUp scrollto">Read More</a> -->
            </div>
          </div>
        </div>

        <!-- Slide 2 -->
        <div class="carousel-item" style="background-image: url('assets/img/slide/bg_2.jpg');">
          <div class="carousel-container">
            <div class="carousel-content container">
              <h2 class="animated fadeInDown">User journey-focussed solutions</h2>
              <p class="animated fadeInUp">We make the best features work better with user-friendly applications.</p>
              <!-- <a href="#about" class="btn-get-started animated fadeInUp scrollto">Read More</a> -->
            </div>
          </div>
        </div>

        <!-- Slide 3 -->
        <div class="carousel-item" style="background-image: url('assets/img/slide/bg_3.jpg');">
          <div class="carousel-container">
            <div class="carousel-content container">
              <h2 class="animated fadeInDown">Creative approach to problem-solving.</h2>
              <p class="animated fadeInUp">One approach doesn't work for all business, we tailor-make solutions for
                your Businesses.</p>
              <!-- <a href="#about" class="btn-get-started animated fadeInUp scrollto">Read More</a> -->
            </div>
          </div>
        </div>

        <!-- Slide 4 -->
        <div class="carousel-item" style="background-image: url('assets/img/slide/bg_1.jpg');">
          <div class="carousel-container">
            <div class="carousel-content container">
              <h2 class="animated fadeInDown">Your business made easy.</h2>
              <p class="animated fadeInUp">Add simplicity to your business with our highly efficient and economical
                solutions.</p>
              <!-- <a href="#about" class="btn-get-started animated fadeInUp scrollto">Read More</a> -->
            </div>
          </div>
        </div>

        <!-- Slide 5 -->
        <div class="carousel-item" style="background-image: url('assets/img/slide/bg_33.jpg');">
          <div class="carousel-container">
            <div class="carousel-content container">
              <h2 class="animated fadeInDown">Great with work, even better with relationships.
              </h2>
              <p class="animated fadeInUp">We deliver results, no doubt. What's more important is we deliver long-term
                relationships.</p>
              <a href="Contact.html" class="btn-get-started animated fadeInUp scrollto">Contact Us</a>
            </div>
          </div>
        </div>

      </div>

      <a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon icofont-rounded-left" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
        <span class="carousel-control-next-icon icofont-rounded-right" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>

    </div>
  </div>
</section><!-- End Hero -->

<main id="main">

  <!-- ======= About Us Section ======= -->
  <section id="about" class="about">
    <div class="container">

      <div class="row no-gutters">
        <div class="col-lg-6 video-box">
          <img src="assets/img/home.jpeg" class="img-fluid" alt="about us">

        </div>

        <div class="col-lg-6 d-flex flex-column justify-content-center about-content">

          <div class="section-title">
            <h2>Not just another software company
            </h2>
            <p style="text-align: justify;"><strong> We are creative software makers.</strong> We don’t look at
              software as just a solution, but as
              an opportunity to
              innovate-create something better than what exists today.
            </p>
          </div>



          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon1"><img src="assets/img/passion 2.png" alt="passion" class="rounded" height="65" width="65">
            </div>
            <!-- <h4 class="title"><a href="">Lorem Ipsum</a></h4><i class="bx bxs-heart bx-tada"></i> -->
            <p class="description" style="text-align: justify;">We are a bunch of passionate individuals who
              pay attention to every detail. This way we keep the interface simple and navigation–super easy.</p>
          </div>

          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon2"><img src="assets/img/latest tech 3.png" class="rounded" alt="technology" height="65"
                width="65">
            </div>
            <!-- <h4 class="title"><a href="">Nemo Enim</a></h4> <i class="bx bx-barcode bx-tada"></i>-->
            <p class="description" style="text-align: justify;">We are
              equipped with latest technologies to provide innovative solutions at pocket-friendly prices.</p>
          </div>
          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon3"><img src="assets/img/artistic 1.png" class="rounded" alt="artists" height="65"
                width="65">
            </div>
            <!-- <h4 class="title"><a href="">Nemo Enim</a></h4> <i class="bx bxs-palette bx-tada"></i>-->
            <p class="description" style="text-align: justify;">
              And yes, we
              are artists by heart. Our software isn’t just a piece of boring solution, but a pleasing application
              with designs you will fall in love with.</p>
          </div>
          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon4"><img src="assets/img/email.png" alt="contact us" class="rounded" height="65"
                width="65"></div>
            <!-- <h4 class="title"><a href="">Nemo Enim</a></h4> <i class="bx bxs-phone-call bx-tada"></i>-->
            <p class="description" style="text-align: justify;">If you are impressed with what you just read, then
              reach
              out to
              us. </p>
          </div>
          <div style="text-align: center; margin-top: 25px;">
            <a href="Contact.html"><button type="button" class="btn btn-sm nbtn">Let’s get creative!</button></a>
          </div>
        </div>

      </div>
    </div>
  </section><!-- End About Us Section -->



  <!-- ======= Counts Section ======= -->

  <section class="for-box">
    <div class="container">
     <div class="row">
       <div class="col-xs-12 col-sm-6 col-md-3 wow rotateInDownLeft animated" data-wow-delay=".1s">
         <div class="for-box-crecl">
           <i class="flaticon-collaboration"></i>
         </div>
         <div class="clr3">
         <h4>
          Strategy & Concept
         </h4>
         </div>
         <div class="wbox">
           <strong>1</strong>
           <p>Understanding the purpose of the application.

            Define the scope.

            Ascertain the resources needed.</p>
            <a href="#" class="clr3-a">+</a>
         </div>

       </div>
       <div class="col-xs-12 col-sm-6 col-md-3 wow rotateInDownLeft animated" data-wow-delay=".2s">
         <div class="for-box-crecl">
           <i class="flaticon-analysis"></i>
         </div>
         <div class="clr3">
         <h4>
          Creative Design
         </h4>
         </div>
         <div class="wbox">
           <strong>2</strong>
           <p>Design the architecture, user interface & asethetics.

            Develop the platforms.

            Programming & prototype.</p>
            <a href="#" class="clr3-a">+</a>
         </div>

       </div>
       <div class="col-xs-12 col-sm-6 col-md-3 wow rotateInDownRight animated" data-wow-delay=".3s">
         <div class="for-box-crecl">
           <i class="flaticon-search-engine"></i>
         </div>
         <div class="clr3">
         <h4>
          Development
         </h4>
         </div>
         <div class="wbox">
           <strong>3</strong>
           <p>Bring in strategy, concept & creative design functionality through software development.</p>
           <a href="#" class="clr3-a">+</a>
         </div>

       </div>
       <div class="col-xs-12 col-sm-6 col-md-3 wow rotateInDownRight animated" data-wow-delay=".4s">
         <div class="for-box-crecl">
           <i class="flaticon-handshake"></i>
         </div>
         <div class="clr3">
         <h4>
          Testing & Support
         </h4>
         </div>
         <div class="wbox">
           <strong>4</strong>
           <p>Test the application for functionality & deploy.
            Provide end-to-end maintenace & training</p>
            <a href="#" class="clr3-a">+</a>
         </div>

       </div>
     </div>
    </div>
   </section>
  <!-- <section class="counts section-bg">
    <div class="container">

      <div class="section-title">
        <h2>The INSEVEN Work Flow</h2>
        <p style="text-align: center;">We follow one step that is constant through all the below stages-- having
          constant communication and great relationship with customer.
        </p>
      </div>

      <div class="row">

        <div class="col-lg-3 col-md-6 text-center" data-aos="fade-up">
          <div class="count-box">
            <img src="assets/img/passion 3.png" class="rounded" alt="Strategy & Concept" height="85" width="85">

            <span data-toggle="counter-up">1</span>
            <p style="font-weight: bold;">Strategy & Concept </p>
            <p>Understanding the purpose of the application.</p>
            <p>Define the scope.</p>
            <p>Ascertain the resources needed.</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 text-center" data-aos="fade-up" data-aos-delay="200">
          <div class="count-box">
            <img src="assets/img/artistic 2.png" class="rounded" alt="Creative Design" height="85" width="85">

            <span data-toggle="counter-up">2</span>
            <p style="font-weight: bold;">Creative Design</p>
            <p>Design the architecture, user interface and asethetics.</p>
            <p>Develop the platforms.</p>
            <p>Programming and prototype.</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 text-center" data-aos="fade-up" data-aos-delay="400">
          <div class="count-box">

            <img src="assets/img/code.png" class="rounded" alt="Software Development" height="85" width="85">
            <span data-toggle="counter-up">3</span>
            <p style="font-weight: bold;">Development</p>
            <p>Bring in functionality through software development.</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 text-center" data-aos="fade-up" data-aos-delay="600">
          <div class="count-box">
            <img src="assets/img/instructions.png" class="rounded" alt="Testing & Support" height="85" width="85">

            <span data-toggle="counter-up">4</span>
            <p style="font-weight: bold;">Testing & Support</p>
            <p>Test the application for functionality and efficient.</p>
            <p>Deploy the application to users.</p>
            <p>Provide end-to-end maintenace, customer training and timely updates.</p>
          </div>
        </div>

      </div>

    </div>
  </section> -->

  <!-- End Counts Section -->

  <!-- ======= Testimonials Section ======= -->
  <section id="testimonials" class="testimonials">
    <div class="container">
      <!-- <div class="section-title">
            <h2 class="text-white">Clients</h2>
          </div> -->

      <div class=" testimonials-carousel">

        <div class="testimonial-item">
          <img src="assets/img/vinyasa.jpeg" class="testimonial-img" alt="Vinyasa">
          <h3>Vinyasa Trust for differently challenged</h3>
          <h4>Abdul Javid-Founder</h4>
          <p>
            <i class="bx bxs-quote-alt-left quote-icon-left"></i>
            Namaste,
              First I would like to thanks to Inseven Technology.
              Inseven is a web designing software company that made our NGO Vinyasa Trust website very uniquely. To be a job means to have the right direction, the work of Girish and his team During this time we found their dedication, punctuality and commitment to be highly commendable. Girish's suggestions on how to manage a website is very satisfying. self Mr. Girish is a kindhearted person.
            <i class="bx bxs-quote-alt-right quote-icon-right"></i>
          </p>
        </div>

        <!-- <div class="testimonial-item">
          <img src="assets/img/Satcom.png" class="testimonial-img" alt="">
          <h3>Satcommobitech</h3>
          <h4>Vishnu-Founder</h4>
          <p>
            <i class="bx bxs-quote-alt-left quote-icon-left"></i>
            "Inseven gave us more than what we asked for-- a beautifully designed website that got us more enquiries. We approached them at the right time, during the Corona pandemic, and the website really helped us. Girish was really patient and gave us the right ideas to reach our customers."
            <i class="bx bxs-quote-alt-right quote-icon-right"></i>
          </p>
        </div> -->

      </div>
    </div>
  </section><!-- End Testimonials Section -->




</main><!-- End #main -->



