import { Component, OnInit } from '@angular/core';
import 'owl.carousel/dist/owl.carousel.min';
declare var $: any;
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    $('.owl-carousel').owlCarousel();
    this.metaService.updateTag({ name: 'description', content: 'Coding meets Creativity. We simplify businesses with innovative technology solutions by combining the power of functionality with the brilliance of design.' });
    this.titleService.setTitle('INSEVEN Technologies | IT Consulting| Web Designing| App Development');
    const findlink = document.getElementsByTagName('link');
    findlink[0].href = 'https://inseventechnologies.com/';
  }

}
