import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HeadingComponent } from './heading/heading.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { ServicesComponent } from './services/services.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { TeamComponent } from './team/team.component';
import { CareerComponent } from './career/career.component';
const routes: Routes = [

  { path: 'home', component: HomeComponent },
  { path: 'career', component: CareerComponent },
    { path: 'team', component: TeamComponent },
  { path: 'portfolio', component: PortfolioComponent },
   { path: 'contact', component: ContactComponent },
  { path: 'services', component: ServicesComponent },
  { path: '',   redirectTo: '/home', pathMatch: 'full' }, // redirect to `first-component`
  { path: '**', component: HomeComponent }
];


@NgModule({
  declarations: [
    AppComponent,
    HeadingComponent,
    NavigationComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    ServicesComponent,
    PortfolioComponent,
    TeamComponent,
    CareerComponent
  ],
  imports: [
    BrowserModule,
    [RouterModule.forRoot(routes)]
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
