import { Component, OnInit } from '@angular/core';
import 'owl.carousel/dist/owl.carousel.min';
import { Title, Meta } from '@angular/platform-browser';

declare var $: any;
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    $('.owl-carousel').owlCarousel();
    this.metaService.updateTag({ name: 'description', content: 'Web Designing, ERP Solutions, mobile apps, customer applications.' });
    this.titleService.setTitle('INSEVEN Technologies | Services');
    const findlink = document.getElementsByTagName('link');
    findlink[0].href = 'https://inseventechnologies.com/services';
  }

}
