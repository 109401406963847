import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.metaService.updateTag({ name: 'description', content: 'Get to know more about our team INSEVEN Management team' });
    this.titleService.setTitle('INSEVEN Technologies | Teams');
    const findlink = document.getElementsByTagName('link');
    findlink[0].href = 'https://inseventechnologies.com/team';
  }

}
