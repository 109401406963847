 <!-- ======= Header ======= -->
 <header id="header">
  <div class="container">

    <div class="logo float-left">
      <h1 class="text-light"><a href="index.html"><span>INSEVEN</span></a></h1>

    </div>

    <nav class="nav-menu float-right d-none d-lg-block">
      <ul >
        <li [ngClass]="{active: isActive('home')}" (click)="select('home')"><a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" >Home</a></li>
        <li><a href="https://softskillerp.web.app/" target="_blank">School ERP</a></li>
        <li [ngClass]="{active: isActive('services')}" (click)="select('services')"><a routerLink="/services" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Services</a></li>
        <li [ngClass]="{active: isActive('portfolio')}" (click)="select('portfolio')"><a routerLink="/portfolio" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Portfolio</a></li>
        <li [ngClass]="{active: isActive('team')}" (click)="select('team')"><a routerLink="/team" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Team</a></li>
        <li [ngClass]="{active: isActive('contact')}" (click)="select('contact')"><a routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Contact Us</a></li>
        <li [ngClass]="{active: isActive('career')}" (click)="select('career')"><a routerLink="/career" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Career</a></li>
      </ul>
    </nav><!-- .nav-menu -->

  </div>
</header><!-- End Header -->
