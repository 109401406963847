import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import 'owl.carousel/dist/owl.carousel.min';
declare var $: any;

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    $('.owl-carousel').owlCarousel();
    this.metaService.updateTag({ name: 'description', content: 'Browse through our work for various partners' });
    this.titleService.setTitle('INSEVEN Technologies | Portfolio');
    const findlink = document.getElementsByTagName('link');
    findlink[0].href = 'https://inseventechnologies.com/portfolio';
  }

}
