<main id="main">
  <!-- ======= Breadcrumbs ======= -->
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">

      <div class="d-flex justify-content-between align-items-center">
        <h2>Portfolio</h2>
        <ol>
          <li><a routerLink="/home">Home</a></li>
          <li>Portfolio</li>
        </ol>
      </div>

    </div>
  </section><!-- End Breadcrumbs -->

  <!-- ======= Portfolio Details Section ======= -->
  <section id="blog" class="blog">
    <div class="container">

     <div class="row">
       <div class="col-md-6">

      </div>
     </div>
      <br>

      <div class="row">

        <div class="col-lg-6 col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <article class="entry border_1">

            <div class="entry-img">
              <img src="assets/img/vinyasatrust.jpeg" alt="" class="img-fluid">
            </div>

            <h2 class="entry-title">
              <a href="https://vinyasatrust.org/home" target="blank">Vinyasa Trust for Differently Challenged
                </a>
            </h2>

            <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center"><i class="icofont-user"></i> <a href="">Website Design</a></li>
              </ul>
            </div>

            <div class="entry-content">
              <b> The Challenge: </b>
              Vinyasa trust was looking for a revamped website with better user navigation, design and content.
             <br>
             <br>
             <b> The Work:</b>
            Though the existing website met the basic requirements, it was far from making an impact. We first started by including better, sharper images that would enhance the look of the website. The content was rewritten to bring out the philosophy and legacy of the trust, and most importantly-- showcase their work. We made the website easy to navigate with lesser number of pages and simplified user experience.
            <br>
            <br>
            <b> The Result:</b>
             An enriched website that would clearly communicate to the donors, potential donors and the public in general. Thanks to the renewed experience, the trust witnessed more enquiries through their website.
            </div>

          </article>
        </div>

        <div class="col-lg-6 col-md-6 d-flex align-items-stretch" data-aos="fade-up">
          <article class="entry border_1">

            <div class="entry-img">
              <img src="assets/img/vishnubharath.jpeg" alt="" class="img-fluid">
            </div>

            <h2 class="entry-title">
              <a href="https://www.vishnubharathalampalli.com/home">Vishnubharathalampalli</a>
            </h2>

            <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center"><i class="icofont-user"></i> <a href="">Website Design</a></li>
              </ul>
            </div>

            <div class="entry-content">
              <p>
                <b>  The Challenge:</b>
                Dr. Vishnu Bharat Alampali came to us with a very clear brief--to create a revamped website, which depicts his beliefs and work in a simple, yet appealing way.
               <br>
               <br>
               <b>  The Work:</b>
               We took up the challenge and created a website that would aptly reflect Dr. Vishnu's personality and achievements. Apart from the necessary pages and designs, we included the option to download books and integrated Facebook blog into the website directly.
               <br>
               <br>
               <b>  The Result: </b>
               The website saw a spike in the number of visits. And we ended up with a happy client.
              </p>
             </div>

          </article><!-- End blog entry -->
        </div>




      </div>


    </div>
  </section>
 <!-- End Details Section -->

  <!-- <section id="portfolio-details" class="portfolio-details">
    <div class="container">

      <div class="row">

        <div class="col-lg-8">
          <h2 class="portfolio-title">Satcommobitech Pvt Ltd</h2>
          <div class="owl-carousel portfolio-details-carousel">
            <img src="assets/img/Satcom1.png" style="width:800px; height:600px;" alt="">

          </div>
        </div>

        <div class="col-lg-4 portfolio-info">
          <h3>Project information</h3>
          <ul>
            <li><strong>Category</strong>: Web design</li>
            <li><strong>Client</strong>:Satcommobitech</li>
            <li><strong>Project date</strong>: 01 March, 2020</li>
            <li><strong>Project URL</strong>: <a href="https://satcommobitech.com/"
                target="blank">https://satcommobitech.com</a>
            </li>
          </ul>

          <p class="text-justify">
            Satcomm, India's first ever doorstep mobile screen replacement service, wanted to increase enquiries, thereby increase sales.
They chose to partner with Inseven to increase visibility and reach maximum potential customers.</p>

<p class="text-justify"><strong> What we did</strong>
We created a simple, yet effective website that would not only introduce Satcomm, but also communicate the Satcomm advantage. The interface is minimalistic, yet contemporary.</p>
<p class="text-justify"><strong> The result?</strong>
Since then, Satcomm has seen an increase in enquiries. As visits to other mobile repair stores dropped, sales increased for Satcomm. It has made the most of the business opportunity brought forth by the pandemic.
          </p>
        </div>

      </div>

    </div>
  </section> -->
  <!-- End Portfolio Details Section -->



</main><!-- End #main -->
