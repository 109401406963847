<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">

    <div class="d-flex justify-content-between align-items-center">
      <h2>Career</h2>
      <ol>
        <li><a routerLink="/home">Home</a></li>
        <li>Career</li>
      </ol>
    </div>

  </div>
</section><!-- End Breadcrumbs -->


<main id="main">

  <section class="counts section-bg">
    <div class="container">

      <div class="section-title">
        <h2>Join our Team</h2>
        <p>Fill the form below if you are interested to be a part of our team. We will get back to you if you meet
          our criteria.
        </p>
      </div>

      <form name="myform" class="form-group">

        <div class="row jumbotron">

          <div class="col-md-12" style="text-align:center;">

          </div>

          <div class="col-md-6 mb-3 input-icons">
            <i class="fa fa-user icon">
            </i>
            <input type="text" class="form-control input-field" placeholder="Name">
          </div>

          <div class="col-md-6 mb-3 input-icons">
            <i class="fa fa-envelope icon">
            </i>
            <input type="text" class="form-control input-field" placeholder="Email-id">
          </div>

          <div class="col-md-6 mb-3 input-icons">
            <i class="fa fa-phone icon">
            </i>
            <input type="text" class="form-control input-field" placeholder="Contact No">
          </div>


          <div class="col-md-6 mb-3 input-icons">
            <i class="fa fa-university icon"></i>
            <input type="text" class="form-control input-field" placeholder="College">
          </div>

          <div class="col-md-6 mb-3 input-icons">
            <i class="fa fa-graduation-cap icon"></i>
            <input type="text" class="form-control input-field" placeholder="Education Degree">
          </div>

          <div class="col-md-6 mb-3 input-icons">
            <i class="fa fa-map icon"></i>
            <input type="text" class="form-control input-field" placeholder="Location">
          </div>

          <div class="col-md-6 mb-3 input-icons">
            <select class="custom-select" id="validationDefault04" required>
              <option selected disabled value="">Position Looking For</option>
              <option>Web Designer</option>
              <option>Software Developer</option>
              <option>Mobile App Developer</option>
              <option>Business Developer</option>
              <option>UX/UI Designer</option>
              <option>Digital Marketing</option>
            </select>
          </div>

          <div class="md-form col-md-6 mb-3 input-icons">

            <!-- <label for="date-picker-example">Try me...</label> -->
            <select class="custom-select" id="validationDefault04" required>
              <option selected disabled value="">Choose Experience</option>
              <option>Fresher</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5 year above</option>
            </select>

          </div>



          <div class="col-md-6 mb-3">
            <form action="/action_page.php">
              <input type="file" id="myFile" name="filename">
            </form>
          </div>

          <div class="col-md-1 align-self-center">
          <button type="button" class="btn btn-primary btn-rounded">Submit</button>

          </div>

        </div>
      </form>
    </div>
  </section><!-- End Counts Section -->


</main><!-- End #main -->
