<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">

    <div class="d-flex justify-content-between align-items-center">
      <h2>Contact Us</h2>
      <ol>
        <li><a routerLink="/home">Home</a></li>
        <li>Contact Us</li>
      </ol>
    </div>

  </div>
</section><!-- End Breadcrumbs -->


<main id="main">

  <!-- ======= Contact Us Section ======= -->
  <section id="contact" class="contact">
    <div class="container">
      <div class="section-title" data-aos="fade-up">
        <h2>Contact Us</h2>
     </div>

      <div class="row">
        <div class="col-lg-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
          <div class="info">
            <div class="address">
              <i class="icofont-google-map"></i>
              <h4>Location:</h4>
              <p>#3, 2nd Floor, 1st Cross, Sharada Nagar, <br>
                Uttarahalli-Vasanthapura Main Road,<br>
                Bangalore:-560061</p>
            </div>

            <div class="email">
              <i class="icofont-envelope"></i>
              <h4>Email:</h4>
              <p>contact@inseventechnologies.com<br>
                inseventech@gmail.com</p>
            </div>

            <div class="phone">
              <i class="icofont-phone"></i>
              <h4>Call:</h4>
              <p>+91 9035203039</p>
            </div>

            <!-- <iframe
            src="https://maps.google.com/maps?q=Address%3A%20%233%2C%202nd%20Floor%2C%201st%20Cross%2C%20Vasanthapura%20Main%20Rd%2C%20Sharada%20Nagar%2C%20Uttarahalli%20Hobli%2C%20Bengaluru%2C%20Karnataka%20560061&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0" style="border: 0; width: 100%; height: 290px;" allowfullscreen></iframe> -->

              <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.2205873694465!2d77.55393731482116!3d12.89353269090712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9af0fc5c3fbbc72!2sINSEVEN%20Technologies!5e0!3m2!1sen!2sin!4v1603904374502!5m2!1sen!2sin"
                frameborder="0" style="border: 0; width: 100%; height: 350px;" allowfullscreen></iframe>
          </div>
        </div>

        <div class="col-lg-6 mt-5 mt-lg-0 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
          <form action="forms/contact.php" method="post" role="form" class="php-email-form">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="name">Your Name</label>
                <input type="text" name="name" class="form-control" id="name" data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars" />
                <div class="validate"></div>
              </div>
              <div class="form-group col-md-6">
                <label for="name">Your Email</label>
                <input type="email" class="form-control" name="email" id="email" data-rule="email"
                  data-msg="Please enter a valid email" />
                <div class="validate"></div>
              </div>
            </div>
            <div class="form-group">
              <label for="name">Subject</label>
              <input type="text" class="form-control" name="subject" id="subject" data-rule="minlen:4"
                data-msg="Please enter at least 8 chars of subject" />
              <div class="validate"></div>
            </div>
            <div class="form-group">
              <label for="name">Message</label>
              <textarea class="form-control" name="message" rows="10" data-rule="required"
                data-msg="Please write something for us"></textarea>
              <div class="validate"></div>
            </div>
            <div class="mb-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">
                Your message has been sent. Thank you!
              </div>
            </div>
            <div class="text-center">
              <button type="submit">Send Message</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- End Contact Us Section -->



</main><!-- End #main -->
